import React, { useState, useEffect } from "react";
import * as auth0Client from "../utils/auth0Client";
import Layout from "../components/global/Layout/Layout";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import Form from "react-bootstrap/Form";
import { regions } from "../services/address";
import { login } from "../redux/slices/Member/member";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import {
  Disclaimer,
  TrackErrorMessage,
} from "../pageHelpers/TravelPassWelcome/TravelPassWelcomeHelpers";
import ILocation from "../@types/ILocation";
import { useAppDispatch, useAppSelector } from "../hooks";
import SEO from "../components/global/SEO/SEO";
import SubTitle from "../components/global/SubTitle/SubTitle";
import Title3 from "../components/global/Title3/Title3";
import PrivacyModal from "../components/common/PrivacyModal/PrivacyModal";
import TermsOfServiceModal from "../components/common/TermsOfServiceModal/TermsOfServiceModal";
import SuccessModal from "../components/common/SuccessModal/SuccessModal";
import Axios from "axios";
import ILoginError from "../@types/ILoginError";
import ILoginUser from "../@types/ILoginUser";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import { Constants } from "../@types/Constants";
import { guestServiceProvider } from "../services/ServiceProvider";
import PasswordValidation from "../utils/passwordChecklistValidator";
import { travelPassEventsGTMDataLayer } from "../utils/datalayers";
import { getCRSSubSource } from "../utils/helpers";

type CompleteSignUpProcessParams = {
  newPassword: string;
  address?: {
    countryCode?: string;
    postalCode?: string;
    stateCode?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
  };
  phones?: {
    mobile?: string;
  };
  args?: string;
};

export const completeSignUpProcess = async (
  params: CompleteSignUpProcessParams
) => {
  const guestService = await guestServiceProvider();
  const response = await guestService.completeSignUpProcess(params);
  return response?.completeSignUpProcess;
};

const TravelPassWelcome = (location: ILocation) => {
  const dispatch = useAppDispatch();

  const { register, trigger, handleSubmit, errors, watch, getValues } = useForm(
    {
      mode: "onBlur",
    }
  );
  const member = useAppSelector((state) => state.member);
  const required = "This field is required.";
  const queryParams = Object.fromEntries(
    new URLSearchParams(location.location.search)
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showResendEmailBtn, setShowResendEmailBtn] = useState(false);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const [isValidToken, setIsValidToken] = useState(true); //TODO: make default value false once token verification code is in
  const [isValidatingToken, setIsValidatingToken] = useState(false); //TODO: make default value true once token verification code is in

  const [showPasswordChecklist, setPasswordChecklist] = useState(false);

  const [showConfirmPasswordChecklist, setConfirmPasswordChecklist] =
    useState(false);

  // get the sign up args that were sent in from api/auth0SendSignUpEmail
  const [signupArgs, setSignupArgs] = useState<{
    auth0UserId: string;
    email: string;
    guestId: string;
    username: string;
    nonce: string;
  }>({
    auth0UserId: "",
    email: "",
    guestId: "",
    username: "",
    nonce: "",
  });
  useEffect(() => {
    const argsObj =
      (queryParams.args && JSON.parse(atob(queryParams.args))) || {};
    argsObj.username = argsObj?.email || ""; // argsObj.email ? argsObj.email.replace(/@/g, '.') : '';
    setSignupArgs(argsObj);
  }, [location]);

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const handlePrivacyModalClose = () => {
    setShowPrivacyModal(false);
  };

  const handlePrivacyModalShow = () => {
    setShowPrivacyModal(true);
  };

  const handleTermsModalClose = () => {
    setShowTermsModal(false);
  };

  const handleTermsModalShow = () => {
    setShowTermsModal(true);
  };

  const handleRevalidatePassword = () => {
    // if confirmpassword field has value then validate it's value against updated newpassword field value
    getValues().confirmpassword && trigger("confirmpassword");
  };

  const handleResendActivationEmail = async () => {
    setIsResendingEmail(true);
    const data = {
      firstname: queryParams.fname,
      lastname: "",
      membernumber: queryParams.member,
      oktaid: queryParams.id,
      email: queryParams.email,
    };
    const response = ""; //await resendActivationEmail(data);
    if (!response || response.error) {
      setErrorMessage(
        response.errorSummary || `User is not allowed for this operation.`
      );
      setShowResendEmailBtn(false);
    } else {
      setShowSuccess(true);
      setErrorMessage("");
    }
    setIsResendingEmail(false);
  };

  if (!queryParams.token) {
    // navigate('/join-travel-pass'); //TODO: once hooked to apis then enable this
  }

  const statesUS = Object.entries(regions["US"]).map(([stateKey, stateObj]) => {
    return (
      <option value={stateKey} key={stateKey}>
        {stateObj.name}
      </option>
    );
  });

  const statesCA = Object.entries(regions["CA"]).map(([stateKey, stateObj]) => {
    return (
      <option value={stateKey} key={stateKey}>
        {stateObj.name}
      </option>
    );
  });

  useEffect(() => {
    (async () => {
      if (member.isLoggedIn) {
        navigate("/account", { state: { isAuthenticated: true } });
      }
    })();
  }, [member.isLoggedIn]);

  type ChangePwdParams = {
    email: string;
    password: string;
    address?: {
      countryCode?: string;
      postalCode?: string;
      stateCode?: string;
      city?: string;
      addressLine1?: string;
      addressLine2?: string;
    };
    phones?: {
      mobile?: string;
    };
  };

  const changePwd = async (params: ChangePwdParams) => {
    const auth0UserId = signupArgs?.auth0UserId;
    const result = await Axios.post(
      `${window.location.origin}/api/signupChangePassword?id=${auth0UserId}&nonce=${signupArgs?.nonce}`,
      params,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  };

  const onSubmit = async (data: any, event: { preventDefault: () => void }) => {
    const initialValues = {
      rlhc_oktaid: queryParams.id,
      recoverytoken: queryParams.token,
    };

    const request = { ...initialValues, ...data };
    if (request.confirmpassword) {
      delete request.confirmpassword;
    }
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    let ret;
    const payload = {
      newPassword: request.newpassword,
      address: {
        addressLine1: request.address_line_1,
        addressLine2: request.address_line_2,
        city: request.city,
        postalCode: request.zip,
        stateCode: request.state,
        countryCode: request.country,
      },
      phones: {
        mobile: request.mobile,
      },
      args: queryParams.args,
    };

    try {
      ret = await completeSignUpProcess(payload);
      await auth0Client.getCDPToken(ret.email, payload.newPassword, onLogin);
      travelPassEventsGTMDataLayer(
        "Complete - Password Set",
        getCRSSubSource()
      );
    } catch (error) {
      setErrorMessage(
        `Oops!! something went wrong while signing you in. Please sign in using new password.`
      );
    }

    setIsSubmitting(false);
  };

  const onLogin = async (errObj: ILoginError | null, data: ILoginUser) => {
    if (errObj) {
      setErrorMessage(
        "Oops!! something went wrong. Please try signing in using new password."
      );
    } else {
      await dispatch(login(data));
    }
  };
  // useEffect(() => {
  //   let source = Axios.CancelToken.source();
  //   let canceledRequest = false;
  //   (async() => {
  //     if(!canceledRequest) {
  //       let isValidToken = await validateToken(queryParams.token);
  //       if(!canceledRequest) {
  //         setIsValidatingToken(false);
  //         if(!isValidToken) {
  //           setErrorMessage(`Your signup link has expired. You can request a new one here `);
  //           setShowResendEmailBtn(true);
  //         } else {
  //           setIsValidToken(true);
  //         }
  //       }
  //     }
  //   })();

  //   return () => {
  //     canceledRequest = true;
  //     source.cancel(`Request cancelled for validate token`);
  //   }
  // },[queryParams.token])

  const stateLabel = watch("country") == "CA" ? "Province" : "State";

  return (
    <Layout>
      <SEO title={"Welcome to Travel Pass"} />
      <h1 className="visually-hidden">Welcome to Travel Pass</h1>
      <Container className="pt-5 mb-5" fluid="sm">
        {isValidatingToken ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg={4}>
                <SubTitle>Sign up (Continued)</SubTitle>
                <Title3 className="mb-4 text-capitalize">
                  Welcome {queryParams.fname}
                </Title3>
                <p>
                  Please finish your profile in order to save on your bookings
                  with Travel Pass.
                </p>
              </Col>
              <Col lg={{ span: 7, offset: 1 }}>
                <p>
                  Password requirements: at least 8 characters, a lowercase
                  letter, an uppercase letter, a number, no parts of your
                  username.
                </p>
                {errorMessage && (
                  <ErrorMessageAlert
                    errorType={Constants.ERRORSTYPE.TRAVELPASS}
                    errorSubType={
                      Constants.ERRORSSUBTYPE.TRAVELPASS.PROFILEACTIVATION
                    }
                    message={errorMessage}
                  >
                    <Alert variant={`danger`}>
                      {errorMessage}
                      {showResendEmailBtn && (
                        <Button
                          className="ms-2"
                          onClick={handleResendActivationEmail}
                          disabled={isResendingEmail}
                        >
                          {isResendingEmail ? `Please wait...` : `Resend Email`}
                        </Button>
                      )}
                    </Alert>
                  </ErrorMessageAlert>
                )}
                {isValidToken && (
                  <Form onSubmit={handleSubmit(onSubmit)} id={`finish-profile`}>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>New password*</Form.Label>
                        <Form.Control
                          type="password"
                          data-tooltip-id="newpassword"
                          name="newpassword"
                          ref={register({
                            required: required,
                            pattern: {
                              value:
                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                              message:
                                "Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your username.",
                            },
                          })}
                          onBlur={() => {
                            handleRevalidatePassword();
                            setPasswordChecklist(false);
                          }}
                          isInvalid={errors.newpassword}
                          aria-invalid={errors.newpassword ? "true" : "false"}
                          onFocus={() => setPasswordChecklist(true)}
                        />
                        <PasswordValidation
                          id="newpassword"
                          password={watch("newpassword")}
                          username={
                            signupArgs.username || signupArgs.email || ""
                          }
                          open={showPasswordChecklist}
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={
                              errors.newpassword && errors.newpassword.message
                            }
                            field="newpassword"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Confirm password*</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmpassword"
                          ref={register({
                            required: required,
                            validate: (value) => {
                              return value === watch("newpassword");
                            },
                          })}
                          isInvalid={errors.confirmpassword}
                          aria-invalid={
                            errors.confirmpassword ? "true" : "false"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={
                              errors.confirmpassword && "Passwords do not match"
                            }
                            field="confirmpassword"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="address1">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                          type="text"
                          name="address_line_1"
                          ref={register}
                          isInvalid={errors.address_line_1}
                          aria-invalid={
                            errors.address_line_1 ? "true" : "false"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={
                              errors.address_line_1 &&
                              errors.address_line_1.message
                            }
                            field="address_line_1"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="address2">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          name="address_line_2"
                          ref={register}
                          isInvalid={errors.address_line_2}
                          aria-invalid={
                            errors.address_line_2 ? "true" : "false"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={
                              errors.address_line_2 &&
                              errors.address_line_2.message
                            }
                            field="address_line_2"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          ref={register}
                          isInvalid={errors.city}
                          aria-invalid={errors.city ? "true" : "false"}
                          maxLength={50}
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={errors.city && errors.city.message}
                            field="city"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="state">
                        <Form.Label>{stateLabel}</Form.Label>
                        <Form.Control
                          as="select"
                          name="state"
                          ref={register}
                          isInvalid={errors.state}
                          aria-invalid={errors.state ? "true" : "false"}
                        >
                          <option value="">--Select State--</option>
                          <optgroup label="United States">{statesUS}</optgroup>
                          <optgroup label="Canada">{statesCA}</optgroup>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={errors.state && errors.state.message}
                            field="state"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="zip">
                        <Form.Label>Zip / Postal code</Form.Label>
                        <Form.Control
                          type="text"
                          name="zip"
                          ref={register}
                          isInvalid={errors.zip}
                          maxLength={20}
                          aria-invalid={errors.zip ? "true" : "false"}
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={errors.zip && errors.zip.message}
                            field="zip"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          as="select"
                          name="country"
                          ref={register}
                          isInvalid={errors.country}
                          aria-invalid={errors.country ? "true" : "false"}
                        >
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={errors.country && errors.country.message}
                            field="country"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile"
                          ref={register}
                          isInvalid={errors.mobile}
                          maxLength={15}
                          aria-invalid={errors.mobile ? "true" : "false"}
                        />
                        <Form.Control.Feedback type="invalid">
                          <TrackErrorMessage
                            message={errors.mobile && errors.mobile.message}
                            field="mobile"
                          />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Button
                      id={`finish-profile-button`}
                      type="submit"
                      className="text-uppercase w-100 mt-4"
                      disabled={isSubmitting || !isValidToken}
                    >
                      {isSubmitting ? `Please wait...` : `Finish Account`}
                    </Button>
                    <Disclaimer>
                      By submitting this form I certify that I have read,
                      understand, and accept the{" "}
                      <Button
                        variant="link"
                        className="modalLinks"
                        onClick={handleTermsModalShow}
                        tabIndex={0}
                      >
                        Terms of Service
                      </Button>
                      &nbsp;and&nbsp;
                      <Button
                        variant="link"
                        className="modalLinks"
                        onClick={handlePrivacyModalShow}
                        tabIndex={0}
                      >
                        Privacy Statement
                      </Button>
                      .
                    </Disclaimer>
                  </Form>
                )}
              </Col>
            </Row>
            <PrivacyModal
              show={showPrivacyModal}
              onHide={handlePrivacyModalClose}
            />
            <TermsOfServiceModal
              show={showTermsModal}
              onHide={handleTermsModalClose}
            />
            <SuccessModal
              show={showSuccess}
              onHide={handleSuccessClose}
              modalSize={"lg"}
            >
              <SubTitle className="text-center mb-1">CONFIRMATION</SubTitle>
              <p className="m-3">
                <i className="font-weight-bold">
                  You will receive a confirmation email shortly to finish your
                  profile.
                </i>
              </p>
            </SuccessModal>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default TravelPassWelcome;
